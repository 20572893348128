
import { defineComponent } from 'vue'
import PageSingle from '@/components/layout/PageSingle.vue'
import TmColoredUl from '@/components/shared/TmColoredUl.vue'

export default defineComponent({
  components: {
    TmColoredUl,
    PageSingle,
  },
})
